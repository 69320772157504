export default class UserModel {
  id!: number;

  nome!: string;

  email?: string | null;

  isRootUser!: boolean;

  isAtivo!: boolean;
}
